export const PRODUCT_CARD_WIDTH = 310;
export const PRODUCT_CARD_HEIGHT = 310;

export const CATEGORY_IMAGE_WIDTH = 384;
export const CATEGORY_IMAGE_HEIGHT = 216;

export const BANNER_IMAGE_WIDTH = 1260;
export const BANNER_IMAGE_HEIGHT = 563;

export const MOBILE_CATEGORY_IMAGE_WIDTH = 208;
export const MOBILE_CATEGORY_IMAGE_HEIGHT = 118;

export const MOBILE_BANNER_IMAGE_WIDTH = 358;
export const MOBILE_BANNER_IMAGE_HEIGHT = 321;

export const TESTIMONIALS_IMAGE_WIDTH = 112;
export const TESTIMONIALS_IMAGE_HEIGHT = 112;

export const MOBILE_TESTIMONIALS_IMAGE_WIDTH = 72;
export const MOBILE_TESTIMONIALS_IMAGE_HEIGHT = 72;

export const DEFAULT_LAYOUT_SELECTION = 5;
export const DEFAULT_NAVBAR_LAYOUT_SELECTION = 'PH3';
export const DEFAULT_SLIDER_LAYOUT_SELECTION = 'HB7';
export const DEFAULT_PRODUCTLIST_LAYOUT_SELECTION = 'PS10';
export const DEFAULT_CATEGORYLIST_LAYOUT_SELECTION = 'CS10';
export const DEFAULT_TESTIMONIALS_LAYOUT_SELECTION = 'Testimonial13';
export const DEFAULT_TEXT_LAYOUT_SELECTION = 'Text1';

export const SHOW_ALL_PRODUCTS_IN_HOME = false;

export const SECTION_HEADING_CLASSNAME = 'uppercase font-semibold md:tracking-308';
export const SLIDER_TOP_PADDING = 'pt-20 md:pt-26';
export const LAYOUT_CONFIG = {
  productList: {
    ShowTaxTag: false,
    showDiscountLabel: false,
  },
};
export const COMMON_TOAST_MESSAGE = {};
