import React from 'react';
export interface CtaProps {
  text: string;
  customClass?: string;
  link: string;
}

const Cta: React.FC<CtaProps> = ({ link, customClass, text, ...props }) => {
  return (
    <>
      {text && link ? (
        <a
          href={link}
          className={`w-168 min-h-48 py-8 text-center text-12 md:text-18 font-bold px-10 bg-theme text-white tracking-widest focus:outline-none rounded-full disabled:opacity-20 flex justify-center items-center flex justify-center items-center border border-transparent ${customClass}`}
          target="_blank"
          rel="noreferrer"
          font-customisation="para-text"
          button-customisation="page-button"
          {...props}
        >
          {text}
        </a>
      ) : null}
    </>
  );
};

export default Cta;
