import { ToastWithKeyType } from '@common/store/toasts/slice';
import { addToast, removeToast } from '@common/store/toasts/slice';
import { ReactComponent as ToastCloseIcon } from '@images/WhiteClose.svg';
import classNames from 'classnames';
import React from 'react';

export interface ToastsProps {
  toasts: ToastWithKeyType[];
  addToast: typeof addToast;
  removeToast: typeof removeToast;
  quickView?: boolean;
}

const Toasts: React.FC<ToastsProps> = ({ toasts, removeToast }) => {
  setTimeout(() => {
    if (toasts.length) {
      document.getElementById('QuickViewToasts')?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
    }
  }, 1000);
  return (
    <div className="absolute top-0 z-30 px-10 min-w-full md:min-w-400">
      {toasts.map((toast) => (
        <div
          key={toast.key}
          className={classNames('shadow-toastShadow rounded-10 mt-10 min-h-60', {
            'bg-errorToast': toast.variant === 'bg-toast-error',
            'bg-successToast': toast.variant !== 'bg-toast-error',
          })}
        >
          <div className="flex w-full items-center p-20 text-14 font-semibold text-white  justify-between">
            <div font-customisation="para-text" dangerouslySetInnerHTML={{ __html: toast.content }}></div>
            <ToastCloseIcon className="cursor-pointer min-w-12 min-h-12 ml-30" onClick={() => removeToast(toast.key)} />
          </div>
        </div>
      ))}
    </div>
  );
};

export default Toasts;
